export const PATTERNS = {
  none: { name: "None", icon: "empty" },
  mackeralTabby: {
    name: "Mackeral Tabby",
    texture: "stripes-mackeral-tabby",
    icon: "empty",
  },
  brokenMackeral: {
    name: "Broken Mackeral",
    texture: "stripes-broken-tabby",
    icon: "empty",
  },
  //mackeral01: { name: 'Mackeral 1', texture: 'mackeral_01', icon: 'empty' },
  //mackeral02: { name: 'Mackeral 2', texture: 'm_tabby_01', icon: 'empty' },
  //classic: { name: 'Classic Tabby 1', texture: 'classic_01', icon: 'classic_01' },
};
