// import { exportX3d } from './x3d_exporter.js';
import CatPrinty from "./components/CatPrinty.vue";
import Vue from "vue";
import store from "./plugins/vuex";
import vuetify from "./plugins/vuetify";

new Vue({
  el: "#app",
  components: { CatPrinty },
  vuetify,
  render: (h) => h(CatPrinty),
  store,
});
