<template>
  <v-container>
    <v-row dense no-gutters>
      <v-card width="100%" flat>
        <v-card-title> Nose Color </v-card-title>
        <v-item-group mandatory v-model="noseColor">
          <v-row dense>
            <v-col v-for="color in noseColors" :key="color">
              <v-item v-slot="{ active, toggle }" :value="color">
                <EditorButton
                  :active="active"
                  @click="toggle"
                  :label="color"
                ></EditorButton>
              </v-item>
            </v-col>
          </v-row>
        </v-item-group>
      </v-card>
    </v-row>
  </v-container>
</template>

<script>
import EditorButton from "../EditorButton.vue";
export default {
  data() {
    return {
      noseColors: ["lighter", "same", "darker", "pink"],
    };
  },
  computed: {
    noseColor: {
      get: function () {
        return this.$store.state.catEditor.noseColor;
      },
      set: function (color) {
        this.$store.commit("setEditorProperty", ["noseColor", color]);
      },
    },
  },
  components: {
    EditorButton,
  },
};
</script>

<style>
.selected-item {
  border-width: 5px !important;
  border-color: var(--v-primary-base) !important;
}

.v-slider__tick--filled,
.v-slider__tick {
  background-color: var(--v-primary-base) !important;
}
</style>
