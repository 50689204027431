<template>
  <v-card>
    <v-card-title> This Feature Coming Soon! </v-card-title>
    <v-card-text>
      FabriCat Studio is in its earliest of days yet. This feature is on our
      radar, and will be coming soon! Help us prioritize it by
      <a :href="feedbackUrl" target="_blank"
        >sending us feedback, and telling us you want this feature now!</a
      >
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  data() {
    return {
      feedbackUrl:
        "https://us6.list-manage.com/survey?u=ab314afce49a8f9670d972e8d&id=ef7dc867a6&attribution=false",
    };
  },
};
</script>
