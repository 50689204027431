<template>
  <v-app class="bg">
    <v-card>
      <LoadingDialog :loading="sceneLoading"></LoadingDialog>
    </v-card>
    <div v-if="assetsLoaded" class="app-container">
      <v-card flat color="transparent">
        <v-app-bar flat color="primary" class="fab-toolbar">
          <v-container fluid>
            <v-row align="center" justify="flex-end">
              <v-toolbar-title>
                <img src="/images/logos/fabricat-logo-red.png" width="100" />
              </v-toolbar-title>
              <v-spacer></v-spacer>
              <v-col cols="auto" class="d-flex align-center mr-3">
                <CountrySelect />
              </v-col>
              <v-col cols="auto" class="d-flex align-center mr-3">
                <OrderModal @upload="exportX3D" />
              </v-col>
              <v-col cols="auto" class="d-flex align-center">
                <CartButton :cart="cart.length" />
              </v-col>
            </v-row>
          </v-container>
        </v-app-bar>
      </v-card>
      <v-container class="main-app" fluid>
        <v-row
          class="mb-6"
          style="flex-grow: 1; overflow: hidden; padding-bottom: 28px"
        >
          <v-col cols="5" class="height-constrained">
            <v-card class="ui-container" color="transparent" flat>
              <v-card class="ui-editor-container" color="transparent" flat>
                <CatEditor></CatEditor>
              </v-card>
            </v-card>
          </v-col>
          <v-col cols="7" class="height-constrained" id="canvas-container-col">
            <v-card flat class="x3d-container height-constrained">
              <x3d
                id="appScene"
                class="x3d-scene"
                width="100%"
                height="100%"
                ref="x3d-container"
              >
                <X3DScene :model-url="baseModel"></X3DScene>
              </x3d>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
      <MeowfestSignup></MeowfestSignup>
      <v-footer color="primary" fixed>
        <v-col class="text-center mt-4 footer" cols="12">
          {{ new Date().getFullYear() }} — <strong>Plorry Corp</strong>
        </v-col>
      </v-footer>
    </div>
  </v-app>
</template>

<script>
import CatEditor from "./CatEditor.vue";
import OrderModal from "./OrderModal.vue";
import CartButton from "./CartButton.vue";
import CountrySelect from "./CountrySelect.vue";
import LoadingDialog from "./LoadingDialog.vue";
import X3DScene from "./X3DScene.vue";
import MeowfestSignup from "./MeowfestSignup.vue";
import { setColor, morph, TEXTURE_LIST, MORPH_LIST } from "../app.js";
import {
  getTabbyColor,
  COLORS,
  getColor,
  getNoseColor,
  drawEye,
} from "../helpers/colorHelpers.js";
import exportX3d from "../helpers/x3d_exporter.js";
import fns from "../helpers/storageModule.js";

const checkout_url = "https://orders.fabricat.studio/create_checkout";
const feedbackUrl =
  "https://us6.list-manage.com/survey?u=ab314afce49a8f9670d972e8d&id=ef7dc867a6&attribution=false";

export default {
  data: function () {
    return {
      loadingDialog: true,
      orderDialog: false,
      baseModel: "models/CAT_LONG_FUR.x3d",
      colors: {
        black: { name: "black", hex: "#1b1b1b", nose: "#151515" },
        blue: { name: "blue", hex: "#747479", nose: "#646469" },
        chocolate: { name: "chocolate", hex: "#7f6357", nose: "#7f6357" },
        ginger: { name: "ginger", hex: "#c78a4e", nose: "#ad5f4d" },
        white: { name: "white", hex: "#ffffff", nose: "#ffe6e6" },
      },
      color: "#ffffff",
      darkColor: "#1b1b1b",
      pattern: "m_tabby_01",
      colorPrimary: {
        color: "white",
        contrast: 1,
      },
      colorSecondary: null,
      whitespotting: 1,
      bodyCanvas: null,
      diluteLevel: 1,
      tortie: false,
      point: false,
      canvasHeight: 0,
    };
  },
  computed: {
    computedColorPrimary() {
      // This computes the literal hex string that will be rendered as a color
      // on the cat model (primary). If it's white, it's white; otherwise, we
      // use a helper method to compute based on color name + dilution level.
      if (this.$store.state.catEditor.color == "white") {
        return COLORS["white"]["color"];
      }
      return getColor(
        this.$store.state.catEditor.color,
        this.$store.state.catEditor.dilution
      );
    },
    computedTabbyPrimary() {
      if (this.$store.state.catEditor.color == "white") {
        return COLORS["white"]["color"];
      }
      return this.$store.state.catEditor.tabbyShade;
    },
    computedColorSecondary() {
      return getColor("ginger", this.diluteLevel);
    },
    computedTabbySecondary() {
      return getColor("ginger", this.diluteLevel, this.colorPrimary.contrast);
    },
    coat() {
      // captures changes for any piece of the coat
      return `${this.colorPrimary.color}|${this.colorPrimary.contrast}|${this.darkColor}|${this.whitespotting}|${this.pattern}|${this.diluteLevel}`;
    },
    cart() {
      let store = this.$store;
      return store.state.cart;
    },
    noseColor() {
      return getNoseColor(
        this.$store.state.catEditor.noseColor,
        this.computedColorPrimary
      );
    },
    skinColor() {
      return getNoseColor(
        this.$store.state.catEditor.skinColor,
        this.computedColorPrimary
      );
    },
    isLoading() {
      return this.$store.state.isLoading;
    },
    assetsLoaded() {
      return !this.$store.state.loading.assets;
    },
  },
  mounted: function () {
    // preload images and models
    let store = this.$store;
    TEXTURE_LIST.forEach((filename) => {
      let img = new Image();
      img.crossOrigin = "anonymous";
      img.src = `textures/${filename}.png`;
      img.onload = function () {
        store.commit("storeTexture", { label: filename, textureData: img });
      };
    });
    MORPH_LIST.forEach((filename) => {
      fetch(`morphs/${filename}.json`)
        .then((response) => response.json())
        .then((json) =>
          store.commit("storeMorph", { label: filename, data: json })
        );
    });
    store.commit("assetsLoaded");
    window.addEventListener("resize", this.calculateCanvasHeight);
  },
  updated: function () {
    if (!this.$refs.x3dContainer || !this.$refs.x3dContainer.$el) {
      return;
    }

    const canvasElement = this.$refs.x3dContainer.$el.querySelector("canvas");
    console.log(canvasElement);
    if (canvasElement) {
      this.calculateCanvasHeight(canvasElement);
    }
  },
  methods: {
    calculateCanvasHeight(canvasComponent) {
      const containerComponent = document.getElementById(
        "canvas-container-col"
      );
      const desiredHeight = containerComponent.offsetHeight;
      console.log("GARBO");
      canvasComponent.height = desiredHeight;
    },
    exportX3D() {
      let store = this.$store;
      store.commit("startUploading");
      exportX3d(store);
    },
    // loadData takes in a set of data and sets the cat's properties to match
    // this is for an action like a user loading up a cat they've previously saved
    loadData({ color, contrast, whitespotting, diluteLevel }) {
      this.colorPrimary = {
        color: color,
        contrast: contrast,
      };
      this.whitespotting = whitespotting;
      this.diluteLevel = diluteLevel;
    },
    exportData() {
      fns.store({
        color: this.colorPrimary,
        pattern: this.pattern,
        whitespotting: this.whitespotting,
        diluteLevel: this.diluteLevel,
      });
    },
    openCheckout() {
      console.log("pressed button");
      window.location = checkout_url;
    },
    getColor() {
      return {
        primary: this.color,
        dark: this.dark_color,
      };
    },
    applyMorphs() {
      const store = this.$store;

      const coordsNode = document.getElementById("cat__coords_ME_body");
      let coords = coordsNode.getAttribute("point");

      store.commit("clearMorphs");
      let points = store.state.baseModelPoints.slice();
      // apply hair length morph
      if (store.state.catEditor.hairLength != "short") {
        store.commit("applyMorph", store.state.catEditor.hairLength);
      }

      const newPoints = store.state.catEditor.appliedMorphs.reduce(
        (pointsSoFar, appliedMorph) => {
          let morphData = store.state.morphs[appliedMorph];
          return morph(morphData, pointsSoFar);
        },
        points
      );
      coords = newPoints.join(" ");
      coordsNode.setAttribute("point", coords);
    },
    collectLayers() {
      let pattern = this.$store.state.catEditor.pattern;
      let baseColor = pattern
        ? this.computedTabbyPrimary
        : this.computedColorPrimary;
      let whitespotting = this.$store.state.catEditor.whitespotting;
      let noseColor = this.noseColor;
      let skinColor = this.skinColor;
      const layers = [
        {
          color: baseColor,
          texture: "base_texture",
          mask: null,
          label: "base-color",
        },
      ];
      // conditionally add pattern-specific layers
      pattern &&
        this.$store.state.catEditor.color !== "white" &&
        layers.push(
          {
            color: "#ffffff",
            texture: "base_texture",
            mask: "patterns/tabby_highlight_bg",
            alpha: 0.5,
            label: "tabby-highlights",
          },
          {
            color: this.computedColorPrimary,
            texture: "base_texture",
            mask: "patterns/tabby_dark_bg",
            alpha: 0.5,
            label: "tabby-dark",
          },
          {
            color: this.computedColorPrimary,
            texture: "base_texture",
            mask: `patterns/${pattern}`,
            label: "tabby-stripes",
          }
        );
      // conditionally draw second color according to chosen tortie pattern
      this.tortie === true &&
        this.$store.state.catEditor.color !== "white" &&
        layers.push(
          {
            color: this.computedColorSecondary,
            texture: "base_texture",
            mask: `tortie_01`,
            label: "tortie-color",
          },
          {
            color: getTabbyColor(
              this.computedColorSecondary,
              "#ffffff",
              this.colorPrimary.contrast
            ),
            texture: "base_texture",
            mask: [`patterns/${this.pattern}_hl`, `tortie_01`],
            label: "tortie-tabby-highlights",
          },
          {
            color: this.computedTabbySecondary,
            texture: "base_texture",
            mask: [`patterns/${this.pattern}`, `tortie_01`],
            label: "tortie-tabby-dark",
          }
        );
      // conditionally add whitespotting
      whitespotting &&
        this.$store.state.catEditor.color !== "white" &&
        whitespotting.forEach((ws) => {
          layers.push({
            color: "white",
            texture: "base_texture",
            mask: `whitespotting/${ws}`,
            label: "whitespotting",
          });
        });
      // add features (nose, ears...)
      layers.push(
        {
          color: skinColor,
          texture: "base_texture",
          mask: "skin",
          label: "skin",
        },
        {
          color: noseColor,
          texture: "noses/nose",
          mask: "noses/nose_shape",
          label: "skin-features",
        },
        {
          color: "#000000",
          texture: "base_texture",
          mask: "lowlight",
          label: "base-lowlights",
          alpha: 0.15,
        },
        {
          color: "#ffffff",
          texture: "base_texture",
          mask: "highlight",
          label: "base-highlights",
        }
      );

      return layers;
    },
    setFur(color) {
      this.color = color;
    },
    setDark(color) {
      this.darkColor = color;
    },
    setPattern(pattern) {
      this.pattern = pattern;
    },
    openFeedback() {
      window.open(feedbackUrl, "_blank");
    },
  },
  watch: {
    colorPrimary: function () {
      setColor(this.collectLayers(), this.$store);
    },
    diluteLevel: function () {
      setColor(this.collectLayers(), this.$store);
    },
    whitespotting: function () {
      setColor(this.collectLayers(), this.$store);
    },
    tortie: function () {
      setColor(this.collectLayers(), this.$store);
    },
    point: function () {
      setColor(this.collectLayers(), this.$store);
    },
  },
  created: function () {
    let x3domScript = document.createElement("script");
    x3domScript.setAttribute("src", "/static/x3dom.js");
    document.head.appendChild(x3domScript);
    let jszipScript = document.createElement("script");
    jszipScript.setAttribute("src", "/static/jszip.min.js");
    document.body.appendChild(jszipScript);

    this.unsubscribe = this.$store.subscribe((mutation) => {
      if (mutation.type === "setEditorProperty") {
        // might have been a morph - do it all (inefficient...)
        if (mutation.payload[0] === "hairLength") {
          this.applyMorphs();
        }
        // An editor propery has been set; reset the texture!
        setColor(this.collectLayers(), this.$store);
        drawEye(this.$store.state.catEditor.eyeColor, this.$store.state);
      }
    });
  },
  components: {
    //CoatEditor,
    CatEditor,
    X3DScene,
    OrderModal,
    CartButton,
    CountrySelect,
    LoadingDialog,
    MeowfestSignup,
  },
};
</script>

<style>
.model-preview {
  display: block;
  float: left;
  width: 65%;
  height: 90vh;
  user-select: none;
  outline: 0;
}

.ui-container {
  width: 100%;
  z-index: 10;
  pointer-events: none;
  height: 100%;
  border: 0;
}

.lower-container {
  width: 100%;
  position: absolute;
  bottom: 0;
}

.upper-container {
  height: 80hv;
}

.ui-editor-container {
  height: 100%;
  float: left;
  z-index: 200;
  pointer-events: auto;
}

.header {
  border-bottom: 3px solid #8fb1d3;
  background-color: #dae6f1;
  height: 175px;
}

.order-btn {
  display: block;
  margin-left: auto;
  margin-right: auto;
  pointer-events: auto;
}

.x3d-scene {
  z-index: 5;
  max-height: calc(100vh - 65px);
}

.fab-toolbar {
  z-index: 20;
  pointer-events: none;
}

.container {
  border: 0;
}

.feedback-button {
  position: absolute;
  bottom: 0;
  right: 0;
}

.v-select {
  max-width: 110px;
}

.col {
  padding: 0;
}

.v-sheet.v-card {
  border-radius: 0;
}

.app-container {
  display: flex;
  flex-direction: column;
  max-height: 100vh;
}

.main-app {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow: hidden;
  padding: 0;
  margin: 0 auto;
  max-width: 1440px;
}

.mb-6 {
  display: flex;
  flex-grow: 1;
  overflow: hidden;
}

.height-constrained {
  display: flex;
  flex-direction: column;
  max-height: 100%;
}

.v-footer {
  margin-top: auto;
}

* {
  box-sizing: border-box;
}

@keyframes scroll {
  0% {
    transform: translate(0);
  }
  100% {
    transform: translate(1820px);
  }
}

.x3d-container {
  background-color: transparent !important;
  display: flex;
  flex-direction: column;
  height: 100%; /* Ensure it respects the parent's height */
  overflow: hidden;
}

h3 {
  color: "white";
  text-align: center;
}

.v-application {
}
</style>
