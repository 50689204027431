<template>
  <v-container>
    <v-row dense no-gutters>
      <v-card width="100%" flat>
        <v-card-title>Hair Length</v-card-title>
        <v-item-group mandatory v-model="hairLength">
          <v-row dense>
            <v-col v-for="length in hairLengths" :key="length">
              <v-item v-slot="{ active, toggle }" :value="length">
                <EditorButton
                  :active="active"
                  @click="toggle"
                  :label="length"
                ></EditorButton>
              </v-item>
            </v-col>
          </v-row>
        </v-item-group>
      </v-card>
    </v-row>
  </v-container>
</template>

<script>
import EditorButton from "../EditorButton.vue";
export default {
  data() {
    return {
      hairLengths: ["short", "long_fur", "eyes_wide"],
    };
  },
  computed: {
    hairLength: {
      get: function () {
        return this.$store.state.catEditor.hairLength;
      },
      set: function (length) {
        this.$store.commit("setEditorProperty", ["hairLength", length]);
      },
    },
  },
  components: {
    EditorButton,
  },
};
</script>

<style>
.selected-item {
  border-width: 5px !important;
  border-color: var(--v-primary-base) !important;
}

.v-slider__tick--filled,
.v-slider__tick {
  background-color: var(--v-primary-base) !important;
}
</style>
