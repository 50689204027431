import Vue from "vue";
import Vuex from "vuex";

import { getColor } from "../helpers/colorHelpers.js";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    loading: {
      assets: true,
      scene: true,
    },
    errors: {},
    catEditor: {
      color: "black",
      dilution: 0,
      pattern: null,
      whitespotting: [],
      calico: null,
      appliedMorphs: [],
      eyeColor: "yellow",
      tabbyShade: "",
      noseColor: "pink",
      colorPoint: null,
      hairLength: "short",
      skinColor: "pink",
      skinVisibility: 5,
    },
    x3domRuntime: null,
    models: {},
    textures: {},
    morphs: {},
    bodyCanvas: null,
    canvases: {},
    tempCanvas: null,
    cart: [],
    isUploading: false,
    uploadStep: 1,
    country: "CA",
    catName: "",
    thumbnails: {},
    prices: {
      model: {
        US: "$35.99",
        CA: "$45.99",
      },
    },
    baseModelPoints: [],
  },
  getters: {
    primaryColor: (state) => {
      return getColor(state.catEditor.color, state.catEditor.dilution);
    },
  },
  mutations: {
    sceneLoaded(state) {
      state.loading.scene = false;
    },
    assetsLoaded(state) {
      state.loading.assets = false;
    },
    storeModel(state, payload) {
      Vue.set(state.models, payload.label, payload.modelData);
    },
    storeTexture(state, payload) {
      Vue.set(state.textures, payload.label, payload.textureData);
    },
    storeMorph(state, payload) {
      Vue.set(state.morphs, payload.label, payload.data);
    },
    setTempCanvas(state, payload) {
      Vue.set(state, "tempCanvas", payload.canvas);
    },
    setBodyCanvas(state, payload) {
      Vue.set(state, "bodyCanvas", payload.canvas);
    },
    addCanvas(state, payload) {
      Vue.set(state.canvases, payload.label, payload.canvas);
    },
    addToCart(state, payload) {
      state.cart.push(payload);
    },
    removeFromCart(state, payload) {
      state.cart = state.cart.filter((item) => item.id != payload.id);
    },
    startUploading(state) {
      state.isUploading = true;
    },
    endUploading(state) {
      state.isUploading = false;
    },
    resetUploadStep(state) {
      state.uploadStep = 1;
    },
    advanceUploadStep(state) {
      state.uploadStep += 1;
    },
    setEditorProperty(state, [property, value]) {
      state.catEditor[property] = value;
    },
    addWhitespotting(state, whitespotting) {
      state.catEditor.whitespotting.push(whitespotting);
    },
    removeWhitespotting(state, whitespotting) {
      state.catEditor.whitespotting = state.catEditor.whitespotting.filter(
        (w) => w != whitespotting
      );
    },
    setCountry(state, country) {
      state.country = country;
    },
    setCatName(state, catName) {
      state.catName = catName;
    },
    addThumbnail(state, payload) {
      state.thumbnails[payload.id] = payload.image;
    },
    removeThumbnail(state, name) {
      delete state.thumbnails[name];
    },
    applyMorph(state, morphName) {
      state.catEditor.appliedMorphs.push(morphName);
    },
    setBaseModel(state, points) {
      state.baseModelPoints = points;
    },
    clearMorphs(state) {
      state.catEditor.appliedMorphs = [];
    },
    setRuntime(state, runtime) {
      Vue.set(state, "x3domRuntime", runtime);
    },
  },
});
