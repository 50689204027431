export const WHITESPOTTINGS = {
  faceMaskBlaze: {
    name: "Mask & Blaze",
    texture: "whitespotting-face-mask-blaze",
    icon: "whitespotting-mask-blaze",
    negates: [],
  },
  faceMaskFull: {
    name: "Full Mask",
    texture: "whitespotting-face-mask-full",
    icon: "whitespotting-full-mask",
    negates: [],
  },
  faceMaskTriangle: {
    name: "Triangle Mask",
    texture: "whitespotting-face-mask-triangle",
    icon: "whitespotting-triangle-mask",
    negates: [],
  },
  faceMask: {
    name: "Mask",
    texture: "whitespotting-face-mask",
    icon: "whitespotting-mask",
    negates: [],
  },
  faceMoustache: {
    name: "Moustache",
    texture: "whitespotting-face-moustache",
    icon: "whitespotting-moustache",
    negates: [],
  },
  faceMoustacheBlaze: {
    name: "Moustache & Blaze",
    texture: "whitespotting-face-moustache-blaze",
    icon: "whitespotting-moustache-blaze",
    negates: [],
  },
  bodyMits: {
    name: "Mitts",
    texture: "whitespotting-body-mits",
    icon: "whitespotting-mitts",
    negates: [],
  },
  bodyGlovesSocks: {
    name: "Gloves & Socks",
    texture: "whitespotting-body-gloves-socks",
    icon: "whitespotting-gloves-socks",
    negates: [],
  },
  bodySocksBib: {
    name: "Socks & Bib",
    texture: "whitespotting-body-socks-bib",
    icon: "whitespotting-socks-bib",
    negates: [],
  },
  bodyBootsBib: {
    name: "Boots & Bib",
    texture: "whitespotting-body-boots-bib",
    icon: "whitespotting-boots-bib",
    negates: [],
  },
  faceCap: {
    name: "Cap",
    texture: "whitespotting-face-cap",
    icon: "empty",
    negates: [],
  },
  faceComplete: {
    name: "Complete Face",
    texture: "whitespotting-face-complete",
    icon: "empty",
    negates: [],
  },
  faceHalf: {
    name: "Half Face",
    texture: "whitespotting-face-half",
    icon: "empty",
    negates: [],
  },
  faceVanEars: {
    name: "Van & Ears",
    texture: "whitespotting-face-van-ears",
    icon: "empty",
    negates: [],
  },
  faceVan: {
    name: "Van",
    texture: "whitespotting-face-van",
    icon: "empty",
    negates: [],
  },
};
