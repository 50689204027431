const computeLighter = function computeLighter(colorInt, percentLighter) {
  return Math.round(colorInt + (256 - colorInt) * percentLighter);
};

const computeIntermediate = function computeIntermediate(
  colorFrom,
  colorTo,
  percent
) {
  let ret = Math.round(colorFrom + (colorTo - colorFrom) * percent);
  return Math.min(ret, 256);
};

export const generateDilutions = function generateDilutions(
  color,
  diluteColor,
  number = 4
) {
  const stepSize = 100 / number;
  const range = [...Array(number).keys()].map((x) => Math.floor(x * stepSize));
  return range.map((percent) =>
    computeIntermediateColor(color, diluteColor, percent)
  );
};

export const diluteColor = function diluteColor(color, diluteLevel) {
  if (diluteLevel !== 0) {
    const [r, g, b] = [
      parseInt(color.slice(1, 3), 16),
      parseInt(color.slice(3, 5), 16),
      parseInt(color.slice(5, 7), 16),
    ];
    const dliutePercent = diluteLevel * 0.06;

    let newColor =
      "#" +
      computeLighter(r, dliutePercent).toString(16) +
      computeLighter(g, dliutePercent).toString(16) +
      computeLighter(b, dliutePercent).toString(16);
    return newColor;
  }
  return color;
};

export const getTabbyColor = function getTabbyColor(
  color,
  tabbyColor,
  contrastLevel
) {
  if (contrastLevel !== 0) {
    const [r, g, b] = [
      parseInt(color.slice(1, 3), 16),
      parseInt(color.slice(3, 5), 16),
      parseInt(color.slice(5, 7), 16),
    ];
    const [tr, tg, tb] = [
      parseInt(tabbyColor.slice(1, 3), 16),
      parseInt(tabbyColor.slice(3, 5), 16),
      parseInt(tabbyColor.slice(5, 7), 16),
    ];
    const contrastPercent = contrastLevel * 0.099;
    let newColor =
      "#" +
      computeIntermediate(r, tr, contrastPercent).toString(16) +
      computeIntermediate(g, tg, contrastPercent).toString(16) +
      computeIntermediate(b, tb, contrastPercent).toString(16);
    return newColor;
  }
  return color;
};

export const getNoseColor = function getNoseColor(noseLabel, color) {
  let noseColor;
  switch (noseLabel) {
    case "darker":
      noseColor = computeIntermediateColor(color, "#000000", 33);
      break;
    case "same":
      noseColor = color;
      break;
    case "lighter":
      noseColor = computeIntermediateColor(color, "#ffffff", 33);
      break;
    case "pink":
      noseColor = "#aa7973";
      break;
    case "tabby":
      // TODO - tabby nose is dark outline, pink inside
      noseColor = "#ffe6e6";
      break;
    default:
      noseColor = "#ffe6e6";
  }
  return noseColor;
};

const computeIntermediateColor = function computeIntermediateColor(
  color,
  secondColor,
  step
) {
  if (step !== 0) {
    const [r, g, b] = [
      parseInt(color.slice(1, 3), 16),
      parseInt(color.slice(3, 5), 16),
      parseInt(color.slice(5, 7), 16),
    ];
    const [tr, tg, tb] = [
      parseInt(secondColor.slice(1, 3), 16),
      parseInt(secondColor.slice(3, 5), 16),
      parseInt(secondColor.slice(5, 7), 16),
    ];
    let newColor =
      "#" +
      computeIntermediate(r, tr, step * 0.01).toString(16) +
      computeIntermediate(g, tg, step * 0.01).toString(16) +
      computeIntermediate(b, tb, step * 0.01).toString(16);
    return newColor;
  }
  return color;
};

export const COLORS = {
  black: {
    color: "#1b1b1b",
    tabby: "#736355",
    dilute: "#c2c5c6",
    diluteName: "blue",
    tabbyDilute: "#e5e7e8",
  },
  chocolate: {
    color: "#302222",
    tabby: "#734319",
    dilute: "#bfb1b6",
    diluteName: "lilac",
    tabbyDilute: "#e5e3e4",
  },
  cinnamon: {
    color: "#7f6357",
    tabby: "#8C5F38",
    dilute: "#F4DCD6",
    diluteName: "fawn",
    tabbyDilute: "#e5ded7",
  },
  red: {
    color: "#c78a4e",
    tabby: "#E7D8D1",
    dilute: "#E7D8D1",
    diluteName: "cream",
    tabbyDilute: "#efe8e1",
  },
  white: { color: "#ffffff" },
};

const ALL_COLORS = {
  black: {},
  chocolate: {},
  cinnamon: {},
  red: {},
  white: {},
};

export const getColor = function getColor(colorFamily, diluteLevel, tabby) {
  if (!ALL_COLORS[colorFamily][diluteLevel]) {
    ALL_COLORS[colorFamily][diluteLevel] = {};
  }
  if (tabby) return getTabby(colorFamily, diluteLevel);
  else return getPrimary(colorFamily, diluteLevel);
};
export const drawEye = function drawEye(eyeColor, state) {
  let eyeImg = state.textures[`eye-${eyeColor}`];
  let eyeCanvas = document.getElementById("canvasEyeTexture_r");
  let eyeCtx = eyeCanvas.getContext("2d");

  eyeCtx.drawImage(eyeImg, 0, 0);
  eyeCanvas.parentNode._x3domNode.invalidateGLObject();

  eyeCanvas = document.getElementById("canvasEyeTexture_l");
  eyeCtx = eyeCanvas.getContext("2d");

  eyeCtx.drawImage(eyeImg, 0, 0);
  eyeCanvas.parentNode._x3domNode.invalidateGLObject();
};

const getPrimary = function getPrimary(colorFamily, diluteLevel) {
  if (!ALL_COLORS[colorFamily][diluteLevel]["color"]) {
    ALL_COLORS[colorFamily][diluteLevel]["color"] = computeIntermediateColor(
      COLORS[colorFamily]["color"],
      COLORS[colorFamily]["dilute"],
      diluteLevel * 20
    );
  }
  return ALL_COLORS[colorFamily][diluteLevel]["color"];
};

const getTabby = function getTabby(colorFamily, diluteLevel) {
  if (!ALL_COLORS[colorFamily][diluteLevel]["tabby"]) {
    ALL_COLORS[colorFamily][diluteLevel]["tabby"] = computeIntermediateColor(
      COLORS[colorFamily]["tabby"],
      COLORS[colorFamily]["dilute"],
      diluteLevel * 20
    );
  }
  return ALL_COLORS[colorFamily][diluteLevel]["tabby"];
};
