<template>
  <v-container flat>
    <v-row>
      <v-col cols="6">
        <v-img width="75" :src="image.src"></v-img>
        {{ catName }}
      </v-col>
      <v-col cols="6">
        {{ price }}
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  props: ["image", "catName", "price"],
};
</script>
