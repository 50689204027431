<template>
  <v-menu :close-on-content-click="false" offset-y>
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        v-bind="attrs"
        v-on="on"
        class="order-btn"
        color="var(--v-primary-darken4)"
      >
        <v-badge color="blue" :content="cart" :value="cart" overlap>
          <v-icon color="white"> mdi-cart-outline </v-icon>
        </v-badge>
      </v-btn>
    </template>

    <v-card>
      <v-list>
        <template v-for="(item, index) in cartItems">
          <v-divider :key="index" v-if="index > 0"></v-divider>
          <v-list-item :key="item.id">
            <!--{{ item.id }}-->
            <CartItem
              :catName="item.name"
              :image="store.state.thumbnails[item.id]"
              :price="price"
            ></CartItem>
            <v-spacer></v-spacer>
            <v-btn
              class="ma-2"
              text
              icon
              color="red lighten-2"
              @click="removeItem(item)"
            >
              <v-icon small>mdi-close-thick</v-icon>
            </v-btn>
          </v-list-item>
        </template>
      </v-list>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary"> Checkout </v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </v-menu>
</template>

<script>
import CartItem from "./CartItem.vue";

export default {
  props: ["cart"],
  computed: {
    cartItems() {
      let store = this.$store;
      return store.state.cart;
    },
    price() {
      const country = this.$store.state.country;
      return this.$store.state.prices.model[country];
    },
    store() {
      return this.$store;
    },
  },
  methods: {
    removeItem(item) {
      this.$store.commit("removeFromCart", item);
    },
  },
  components: { CartItem },
};
</script>

<style>
.modal {
  z-index: 300;
  pointer-events: auto;
}

.order-btn {
  z-index: 200;
  pointer-events: auto;
  display: block;
}
</style>
