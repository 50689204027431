const storageURL = "https://storage.fabricat.studio";

export default {
  store: function (modelData) {
    const data = {
      ...modelData,
      timestamp: Date.now(),
    };
    fetch(`${storageURL}/model`, {
      method: "POST",
      body: JSON.stringify({
        userEmail: "andrew@andrewgardner.ca",
        modelId: btoa(Object.values(data)).slice(-8),
        modelData: data,
      }),
      headers: {},
    }).then((response) => {
      console.log(response);
    });
  },

  load: function (modelId) {
    fetch(`${storageURL}/model/${modelId}`).then((response) => response.json());
  },
};
