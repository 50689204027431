import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    options: { customProperties: true },
    themes: {
      light: {
        //      primary: "#ACA0FF",
        //      secondary: "#FFFFE6",
        //      accent: "#FF8576",
        //primary: "#5756a9",
        //accent: "#dab5d6",
        //info: "#e8d9ad",
        //secondary: "#bfd8b9",
      },
    },
    typography: {
      //fontFamily: "Chewy",
    },
  },
});
