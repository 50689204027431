<template>
  <v-card outlined color="secondary">
    <v-window v-model="about">
      <v-window-item v-for="(panel, key) in items" :key="`card-${key + 1}`">
        <component :is="panel.component"></component>
      </v-window-item>
    </v-window>
  </v-card>
</template>

<script>
import HowItWorks from "./aboutPanels/HowItWorks.vue";

export default {
  data() {
    return {
      items: [{ component: HowItWorks }],
    };
  },
};
</script>
