<template>
  <v-container>
    <v-item-group mandatory v-model="selectedColor">
      <v-row dense class="my-1" v-for="dilute in 5" :key="dilute">
        <v-col v-for="color in colors" :key="color.name" class="pa-2">
          <v-item
            v-slot="{ active }"
            :value="getToggleValue(color, dilute)"
            v-if="color.name !== 'white' || dilute === 1"
          >
            <EditorButton
              :color="computeColor(color.color, dilute)"
              :active="active"
              @click="setSelectedColor({ color, dilute })"
              round
            ></EditorButton>
          </v-item>
        </v-col>
      </v-row>
    </v-item-group>
    <v-row no-gutters></v-row>
  </v-container>
</template>

<script>
import { COLORS, diluteColor } from "../../helpers/colorHelpers.js";
import EditorButton from "../EditorButton.vue";
export default {
  data() {
    return {
      colors: Object.keys(COLORS).map((key) => ({
        name: key,
        color: COLORS[key].color,
      })),
      currentValue: {},
    };
  },
  computed: {
    selectedColor: {
      get: function () {
        return this.currentValue;
      },
      set: function ({ color, dilute }) {
        this.currentValue = { color, dilute };
      },
    },
  },
  methods: {
    computeColor(color, dilute) {
      return diluteColor(color, (dilute - 1) * 2);
    },
    getToggleValue(colorName, dilute) {
      return { color: colorName, dilute: dilute };
    },
    setSelectedColor({ color, dilute }) {
      this.selectedColor = { color, dilute };
      console.log("called with ", { color: color.name, dilute });
      this.$store.commit("setEditorProperty", ["color", color.name]);
      this.$store.commit("setEditorProperty", ["dilution", dilute - 1]);
    },
  },
  components: {
    EditorButton,
  },
};
</script>

<style>
.v-slider__tick--filled,
.v-slider__tick {
  background-color: var(--v-primary-base) !important;
}
</style>
