<template>
  <v-card flat class="editor-tab">
    <v-expansion-panels focusable flat mandatory accordion>
      <v-expansion-panel
        v-for="panel in panels"
        :key="panel.title"
        :disabled="panel.whiteDisable && selectedColor == 'white'"
      >
        <v-expansion-panel-header color="var(--v-primary-lighten2)">
          <h3>
            {{ panel.title }}
          </h3>
        </v-expansion-panel-header>
        <v-expansion-panel-content color="var(--v-accent-lighten3)">
          <component :is="panel.component"></component>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </v-card>
</template>

<script>
import ColorPanel from "./editorPanels/ColorPanel.vue";
import WhitespottingPanel from "./editorPanels/WhitespttingPanel.vue";
import PatternPanel from "./editorPanels/PatternPanel.vue";
import ComingSoon from "./ComingSoon.vue";
export default {
  computed: {
    selectedColor() {
      return this.$store.state.catEditor.color;
    },
  },
  data() {
    return {
      panels: [
        { title: "main color", component: ColorPanel },
        {
          title: "stripes & spots",
          component: PatternPanel,
          whiteDisable: true,
        },
        {
          title: "whitespotting",
          component: WhitespottingPanel,
          whiteDisable: true,
        },
        {
          title: "tortoiseshell",
          component: ComingSoon,
          whiteDisable: true,
        },
        {
          title: "color point",
          component: ComingSoon,
          whiteDisable: true,
        },
      ],
    };
  },
};
</script>

<style scoped>
.editor-tab {
  max-height: inherit;
  overflow: auto;
}
</style>
