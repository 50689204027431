/* eslint-disable */
// list of available texture filenames to load (png format)
export const TEXTURE_LIST = [
  "eye-hazel",
  "eye-blue",
  "eye-green",
  "eye-yellow",
  "eye-orange",
  "tabby",
  "white",
  "whitespotting/whitespotting-body-boots-bib",
  "whitespotting/whitespotting-body-gloves-socks",
  "whitespotting/whitespotting-body-mits",
  "whitespotting/whitespotting-body-socks-bib",
  "whitespotting/whitespotting-face-cap",
  "whitespotting/whitespotting-face-complete",
  "whitespotting/whitespotting-face-half",
  "whitespotting/whitespotting-face-mask-blaze",
  "whitespotting/whitespotting-face-mask-full",
  "whitespotting/whitespotting-face-mask-triangle",
  "whitespotting/whitespotting-face-mask",
  "whitespotting/whitespotting-face-moustache-blaze",
  "whitespotting/whitespotting-face-moustache",
  "whitespotting/whitespotting-face-van-ears",
  "whitespotting/whitespotting-face-van",
  "face_test",
  "noses/nose",
  "noses/nose_shape",
  "base_texture",
  "patterns/mackeral_tabby",
  "patterns/classic_01",
  "patterns/classic_01_hl",
  "patterns/mackeral_tabby_hl",
  "patterns/test_tabby",
  "patterns/test_tabby_hl",
  "patterns/m_tabby_01",
  "patterns/m_tabby_01_hl",
  "patterns/mackeral_01",
  "patterns/mackeral_01_hl",
  "patterns/point",
  "patterns/color-point-lo-contrast",
  "patterns/stripes-mackeral-tabby",
  "patterns/stripes-broken-tabby",
  "patterns/tabby_stripes",
  "patterns/tabby_dark_bg",
  "patterns/tabby_highlight_bg",
  "tortie_01",
  "skin",
  "skin-ears",
  "skin-face",
  "highlight",
  "lowlight",
];

export const MORPH_LIST = ["long_fur", "eyes_wide"];

export const shaderNode = `
<ComposedShader DEF="SmoothShader">
    <field name="lightDirection" type="SFVec3f" value="0 0 -1"></field>
    
    <ShaderPart type="VERTEX">
        <![CDATA[
        attribute vec3 position; // Vertex position
        attribute vec3 normal;   // Vertex normal

        uniform mat4 modelViewMatrix;            // Model-view matrix
        uniform mat4 modelViewProjectionMatrix;  // Model-view-projection matrix
        uniform mat4 normalMatrix;               // Normal matrix

        varying vec3 vNormal;    // Pass normal to fragment shader
        varying vec3 vPosition;  // Pass position to fragment shader

        void main() {
            vNormal = normalize(vec3(normalMatrix * vec4(normal, 0.0)));  // Transform the normal
            vPosition = vec3(modelViewMatrix * vec4(position, 1.0));      // Transform the position
            gl_Position = modelViewProjectionMatrix * vec4(position, 1.0); // Calculate final vertex position
        }
        ]]>
    </ShaderPart>
    
<ShaderPart type="FRAGMENT">
    <![CDATA[
    #ifdef GL_FRAGMENT_PRECISION_HIGH
    precision highp float;
    #else
    precision mediump float;
    #endif

    uniform vec3 lightDirection;

    varying vec3 vNormal;
    varying vec3 vPosition;

    void main() {
        vec3 normalizedNormal = normalize(vNormal);

        // Basic diffuse lighting
        float diffuse = max(dot(normalizedNormal, -lightDirection), 0.0);
        
        // Ambient occlusion approximation
        float ao = pow(1.0 - abs(dot(normalizedNormal, vec3(0.0, 1.0, 0.0))), 2.0);

        vec3 baseColor = vec3(1.0, 1.0, 1.0); // Base color of the object
        vec3 lighting = baseColor * diffuse * ao; // Combine diffuse with AO

        gl_FragColor = vec4(lighting, 1.0);
    }
    ]]>
</ShaderPart></ShaderPart></ComposedShader>
`;

const STATE = {
  eyeTexture: {
    name: null,
    fileData: null,
  },
  currentTexture: {
    name: null,
    fileData: null,
  },
  scene: null,
  runtime: null,
  bodyCanvas: null,
  whitespottingCanvas: null,
  tempCanvas1: null,
  tempCanvas2: null,
};

const cache = {
  textures: {},
  models: {},
};

const init = function init() {
  let scene = document.getElementById("appScene");
  STATE.scene = scene;
  STATE.runtime = scene.runtime;
};

const main = function main() {
  STATE.runtime.disableKeys();
  STATE.runtime.disableMiddleDrag();
  STATE.runtime.disableRightDrag();
  STATE.scene.setAttribute("background", "white");
};

const initCanvas = function initCanvas(
  parentNodeId,
  idName,
  height = 1024,
  width = 1024
) {
  let parentNode = document
    .getElementById(parentNodeId)
    .getElementsByTagName("Appearance")[0];
  let canvasNode = `
      <Texture id="${parentNodeId}_texture">
      </Texture>
      <Material diffuseColor="0.510 0.528 0.581"
							          specularColor="0.000 0.000 0.000"
							          emissiveColor="0.000 0.000 0.000"
							          ambientIntensity="0"
							          shininess="0.000"
							          transparency="0.0" />`;
  let can = document.createElement("canvas");
  can.id = idName;
  can.height = height;
  can.width = width;
  parentNode.innerHTML = canvasNode;
  canvasNode = document.getElementById(`${parentNodeId}_texture`);
  canvasNode.appendChild(can);
};

export const setColor = function setColor(colorLayers, store) {
  // array of objs {color: hex, texture: img, mask: img}
  let canvas = store.state.bodyCanvas;
  let ctx = canvas.getContext("2d");

  // Draw the primary coat on the canvas
  colorLayers.forEach(({ color, texture, mask, label, alpha }) => {
    if (mask) {
      if (!Array.isArray(mask)) {
        mask = [mask];
      }
      mask = mask.map((m) => store.state.textures[m]);
    }
    let coat = makeCoat(
      color,
      store.state.textures[texture],
      mask,
      label,
      store
    );
    if (!alpha) {
      alpha = 1;
    }
    ctx.globalAlpha = alpha;
    ctx.drawImage(coat, 0, 0);
  });
  canvas.parentNode._x3domNode.invalidateGLObject();
};

const clearCanvas = function clearCanvas(canvas) {
  let ctx = canvas.getContext("2d");

  ctx.clearRect(0, 0, canvas.width, canvas.height);
};

const makeCoat = function makeCoat(colorVal, textureImage, mask, label, store) {
  // Returns a canvas that contains a coat of the specified color drawn
  let cvs;
  if (label in store.state.canvases) {
    cvs = store.state.canvases[label];
  } else {
    cvs = document.createElement("canvas");
    cvs.height = 1024;
    cvs.width = 1024;
    store.commit("addCanvas", { label: label, canvas: cvs });
  }

  let ctx = cvs.getContext("2d");
  let cvs2 = store.state.tempCanvas;
  let ctx2 = cvs2.getContext("2d");

  clearCanvas(cvs);
  clearCanvas(cvs2);

  ctx.globalCompositeOperation = "source-over";

  ctx.drawImage(textureImage, 0, 0);

  ctx2.fillStyle = colorVal;
  ctx2.fillRect(0, 0, cvs2.width, cvs2.height);

  ctx.globalCompositeOperation = "source-in";
  ctx.drawImage(cvs2, 0, 0);

  clearCanvas(cvs2);

  ctx2.fillStyle = "black";
  ctx2.fillRect(0, 0, cvs2.width, cvs2.height);
  ctx2.drawImage(cvs, 0, 0);

  clearCanvas(cvs);
  //ctx.fillStyle = 'white';
  //ctx.fillRect(0, 0, cvs.width, cvs.height);
  if (mask) {
    // Trim coat to mask or masks
    mask.forEach((m, i) => {
      if (i === 0) {
        ctx.globalCompositeOperation = "source-over";
      } else {
        ctx.globalCompositeOperation = "source-in";
      }
      ctx.drawImage(m, 0, 0);
    });
    ctx.globalCompositeOperation = "source-atop";
  } else {
    ctx.globalCompositeOperation = "source-over";
  }
  ctx.drawImage(cvs2, 0, 0);

  return cvs;
};

const prepScene = function prepScene() {
  // Do a little cleanup of our x3d file

  // get and delete BG - we don't need it
  let bg = document.getElementsByTagName("Background");
  for (let bg_elem of bg) {
    bg_elem.parentNode.removeChild(bg_elem);
  }
};

export const morph = function morph(morph_data, points) {
  // morph is loaded into state already
  // create a copy of the Coords.point for the body object
  let newCoords = points;
  let coordsNode = document.getElementById("cat__coords_ME_body");
  let coords = coordsNode.getAttribute("point");
  // for every index in the morph blob, perform the addition operation
  for (var k in morph_data) {
    newCoords[k] = (Number(points[k]) + Number(morph_data[k])).toFixed(6);
  }
  // set the new array as the value for Coords.point
  return newCoords;
  // do whatever reload operation needs to be done to render it?
};
