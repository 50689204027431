<template>
  <v-container>
    <v-row dense no-gutters>
      <v-card width="100%" flat>
        <v-card-title> Lighter Shade </v-card-title>
        <v-item-group mandatory v-model="tabbyShade">
          <v-row dense>
            <v-col v-for="color in tabbyShadeSet" :key="color" class="pa-1">
              <v-item v-slot="{ active, toggle }" :value="color">
                <EditorButton
                  :color="color"
                  :active="active"
                  @click="toggle"
                ></EditorButton>
              </v-item>
            </v-col>
          </v-row>
        </v-item-group>
      </v-card>
    </v-row>
    <v-item-group mandatory v-model="selectedPattern">
      <v-row dense class="my-5">
        <v-spacer></v-spacer>
        <v-col v-for="pattern in patterns" :key="pattern.name" cols="3" md="4">
          <v-item v-slot="{ active, toggle }" :value="pattern.texture">
            <EditorButton
              :active="active"
              @click="toggle"
              :icon="pattern.icon"
              :label="pattern.name"
            ></EditorButton>
          </v-item>
        </v-col>
        <v-spacer></v-spacer>
      </v-row>
    </v-item-group>
  </v-container>
</template>

<script>
import { PATTERNS } from "../../helpers/patternHelper.js";
import {
  COLORS,
  getColor,
  generateDilutions,
} from "../../helpers/colorHelpers.js";
import EditorButton from "../EditorButton.vue";
export default {
  data() {
    return {
      patterns: Object.keys(PATTERNS).map((key) => ({
        label: key,
        ...PATTERNS[key],
      })),
    };
  },
  computed: {
    tabbyShadeSet() {
      return this.selectedColor == "white"
        ? []
        : generateDilutions(
            getColor(this.selectedColor, this.dilution, true),
            COLORS[this.selectedColor]["tabbyDilute"],
            Math.max(1, Math.min(4, 5 - this.dilution))
          );
    },
    selectedColor() {
      return this.$store.state.catEditor.color;
    },
    dilution() {
      return this.$store.state.catEditor.dilution;
    },
    selectedPattern: {
      get: function () {
        return this.$store.state.catEditor.pattern;
      },
      set: function (pattern) {
        this.$store.commit("setEditorProperty", ["pattern", pattern]);
      },
    },
    tabbyShade: {
      get: function () {
        return this.$store.state.catEditor.tabbyShade;
      },
      set: function (shade) {
        this.$store.commit("setEditorProperty", ["tabbyShade", shade]);
      },
    },
  },
  components: {
    EditorButton,
  },
};
</script>

<style>
.selected-item {
  border-width: 5px !important;
  border-color: var(--v-primary-base) !important;
}

.v-slider__tick--filled,
.v-slider__tick {
  background-color: var(--v-primary-base) !important;
}
</style>
