<script>
//import { shaderNode } from "../app";
export default {
  props: ["modelUrl"],
  render: function (createElement) {
    return createElement("scene", [
      createElement("inline", {
        attrs: {
          MapDEFToID: true,
          nameSpaceName: "cat",
          id: "lecat",
          url: this.modelUrl,
        },
      }),
      createElement("transform", {
        attrs: {
          rotation: "1 0 0 1.570796",
        },
      }),
      createElement(
        "transform",
        {
          attrs: {
            rotation: "1 0 0 1.570796",
          },
        },
        [
          createElement("shape", [
            createElement("appearance", [
              createElement("imagetexture", {
                attrs: {
                  url: "textures/shadow.png",
                },
              }),
            ]),
            createElement("plane", {
              attrs: {
                size: "5,5",
                solid: "false",
                center: "0,0,1",
              },
            }),
          ]),
        ]
      ),
      createElement("NavigationInfo", {
        attrs: {
          type: "turntable",
          typeParams: "[-0.4,35,1.2,2]",
          explorationMode: "rotate",
        },
      }),
      createElement("Viewpoint", {
        attrs: {
          centerOfRotation: "0,0.75,0",
          position: "0,0.75,-5",
          id: "x3d-viewpoint-all",
          set_bind: "true",
        },
      }),
      createElement("Viewpoint", {
        attrs: {
          centerOfRotation: "0,1.2,-0.75",
          position: "0,1.2,-3",
          id: "x3d-viewpoint-face",
          set_bind: "false",
        },
      }),
    ]);
  },
  mounted: function () {
    // Have to manually set the model's onload here - setting as HTML attr doesn't seem to click
    var e = document.getElementById("lecat");
    e.onload = this.initRuntime;
  },
  methods: {
    setViewpoint(centerOfRotation, position) {
      let centerOfRotationStr = centerOfRotation.join();
      let positionStr = position.join();
      let viewpoint = document.getElementById("x3d-viewpoint");
      viewpoint.setAttribute("centerOfRotation", centerOfRotationStr);
      viewpoint.setAttribute("position", positionStr);
    },
    initRuntime() {
      this.prepScene();
      this.initCanvas("cat__group_ME_body", "canvasBodyTexture");
      this.initCanvas("cat__group_ME_eye_l", "canvasEyeTexture_l", 512, 512);
      this.initCanvas("cat__group_ME_eye_r", "canvasEyeTexture_r", 512, 512);
      let textureCanvas = document.getElementById("canvasBodyTexture");

      let ctx = textureCanvas.getContext("2d");

      let imge = this.$store.state.textures["base_texture"];

      ctx.drawImage(imge, 0, 0);
      this.$store.commit("setBodyCanvas", { canvas: textureCanvas });
      textureCanvas.parentNode._x3domNode.invalidateGLObject();

      let eyeColor = this.$store.state.catEditor.eyeColor;
      let eyeImg = this.$store.state.textures[`eye-${eyeColor}`];
      let eyeCanvas = document.getElementById("canvasEyeTexture_r");
      let eyeCtx = eyeCanvas.getContext("2d");

      eyeCtx.drawImage(eyeImg, 0, 0);
      eyeCanvas.parentNode._x3domNode.invalidateGLObject();

      eyeCanvas = document.getElementById("canvasEyeTexture_l");
      eyeCtx = eyeCanvas.getContext("2d");

      eyeCtx.drawImage(eyeImg, 0, 0);
      eyeCanvas.parentNode._x3domNode.invalidateGLObject();

      let tempCanvas = document.createElement("canvas");
      tempCanvas.height = 1024;
      tempCanvas.width = 1024;

      this.$store.commit("setTempCanvas", { canvas: tempCanvas });
      this.$store.commit("sceneLoaded");

      this.$store.commit("setEditorProperty", ["color", "white"]);

      const runtime = document.getElementById("appScene").runtime;
      this.$store.commit("setRuntime", runtime);
      runtime.disableKeys();
      runtime.disableMiddleDrag();
      runtime.disableRightDrag();

      this.storeBaseModelPoints(this.$store);
    },
    storeBaseModelPoints(store) {
      let coordsNode = document.getElementById("cat__coords_ME_body");
      let coords = coordsNode.getAttribute("point");
      console.log(coordsNode);
      // parse it into an indexed array
      let coordsList = coords.split(" ");
      console.log("storing points now");
      store.commit("setBaseModel", coordsList);
      console.log("storing points DONE");
    },
    prepScene() {
      // get and delete BG - we don't need it
      let bg = document.getElementsByTagName("Background");
      for (let bg_elem of bg) {
        bg_elem.parentNode.removeChild(bg_elem);
      }
    },
    initCanvas(parentNodeId, idName, height = 1024, width = 1024) {
      let parentNode = document
        .getElementById(parentNodeId)
        .getElementsByTagName("Appearance")[0];
      let canvasNode = `
              <Texture id="${parentNodeId}_texture">
              </Texture>
              <CommonSurfaceShader
                emissiveFactor='0.3,0.3,0.3'
              ></CommonSurfaceShader>
        `;
      let can = document.createElement("canvas");
      can.id = idName;
      can.height = height;
      can.width = width;
      parentNode.innerHTML = canvasNode;
      canvasNode = document.getElementById(`${parentNodeId}_texture`);
      canvasNode.appendChild(can);
    },
    drawEye(eyeColor) {
      let eyeImg = this.$store.state.textures[`eye-${eyeColor}`];
      let eyeCanvas = document.getElementById("canvasEyeTexture_r");
      let eyeCtx = eyeCanvas.getContext("2d");

      eyeCtx.drawImage(eyeImg, 0, 0);
      eyeCanvas.parentNode._x3domNode.invalidateGLObject();

      eyeCanvas = document.getElementById("canvasEyeTexture_l");
      eyeCtx = eyeCanvas.getContext("2d");

      eyeCtx.drawImage(eyeImg, 0, 0);
      eyeCanvas.parentNode._x3domNode.invalidateGLObject();
    },
  },
};
</script>
