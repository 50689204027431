import * as JSZip from "jszip";
import { saveAs } from 'file-saver';
import { takeScreengrab } from "./canvasScreengrabHelper.js";

const zip = new JSZip();
const GENERATOR_NAME = "goofy export tool";
const UPLOAD_ENDPOINT = "https://orders.fabricat.studio/upload";

const exportX3d = async (store) => {
  let filename = "catness.x3d";

  let x3dData = generateX3dContent(filename);
  let bodyCanvas = document.getElementById("canvasBodyTexture");
  let eyeCanvas = document.getElementById("canvasEyeTexture_l");

  let canvasFile = canvasToImage(bodyCanvas);
  let eyeFile = canvasToImage(eyeCanvas);

  //console.log(canvasFile);

  zip.file(filename, x3dData);
  zip.file("body.png", canvasFile, { base64: true });
  zip.file("eye.png", eyeFile, { base64: true });

  const blob = await zip.generateAsync({
    type: "blob",
    compression: "DEFLATE",
    compressionOptions: {
      level: 5,
    },
  });
  saveAs(blob, "file.zip"); 
  //let postData = {upload: blob};
  let formData = new FormData();
  formData.append("upload", blob);
  // TODO - ID should be generated from hash of Data blob - either here, or passed into export function
  const id = Math.random()
    .toString(36)
    .replace(/[^A-Za-z0-9]+/g, "")
    .substr(0, 8)
    .toUpperCase();
  const name = store.state.catName;
  formData.append("name", name);

  let req = new XMLHttpRequest();
  req.open("POST", UPLOAD_ENDPOINT);
  //req.onload = (res => console.log(res));
  await req.send(formData);

  req.onreadystatechange = () => {
    if (req.readyState === XMLHttpRequest.DONE) {
      const res = JSON.parse(req.responseText);
      console.log(res);
      const cartItem = {
        id: id,
        name: res.model.id,
      };
      // window.location.href = res.url;
      store.commit("addToCart", cartItem);
      store.commit("addThumbnail", {
        id: id,
        name: name,
        image: takeScreengrab(),
      });
      store.commit("endUploading");
      store.commit("advanceUploadStep");
    }
  };
};

const canvasToImage = function canvasToImage(canvas) {
  //var tempImg = new Image();
  //tempImg.src = canvas.toDataURL("image/png");
  //return tempImg
  return canvas
    .toDataURL("image/png")
    .replace(/^data:image\/(png|jpg);base64,/, "");
};

const generateX3dContent = function generateX3dContent(filename) {
  return generateX3dHeader(filename) + generateScene() + generateX3dFooter();
};

const generateX3dHeader = function generateX3dHeader(filename) {
  return `<?xml version="1.0" encoding="UTF-8"?>
<!DOCTYPE X3D PUBLIC "ISO//Web3D//DTD X3D 3.0//EN" "http://www.web3d.org/specifications/x3d-3.0.dtd">
<X3D version="3.0" profile="Immersive" xmlns:xsd="http://www.w3.org/2001/XMLSchema-instance" xsd:noNamespaceSchemaLocation="http://www.web3d.org/specifications/x3d-3.0.xsd">
	<head>
		<meta name="filename" content="${filename}" />
		<meta name="generator" content="${GENERATOR_NAME}" />
	</head>`;
};

const generateX3dFooter = function generateX3dFooter() {
  return `
</X3D>
`;
};

const generateScene = function generateScene() {
  let scene = stripCanvasTexture(
    document.getElementById("lecat").cloneNode(true),
    [
      ["#cat__group_ME_body", "body"],
      ["#cat__group_ME_eye_r", "eye"],
      ["#cat__group_ME_eye_l", "eye"],
    ]
  );

  //console.log(scene);

  return `
    <Scene>
        ${scene.innerHTML}
    </Scene>`;
};

const stripCanvasTexture = function stripCanvasTexture(sceneNode, objIds) {
  // For the app we use a canvas texture, but to 3D print
  // we need an Image > replace the canvases with the image tag.
  objIds.forEach(([objId, filename]) => {
    console.log(objId);
    let appearanceNode = sceneNode
      .querySelector(objId)
      .querySelector("Appearance");

    appearanceNode.innerHTML = "";

    let imgNode = document.createElementNS("x3dom", "ImageTexture");
    imgNode.setAttribute("DEF", filename + "_png");
    imgNode.setAttribute("url", filename + ".png");
    appearanceNode.appendChild(imgNode);
    // `<ImageTexture DEF="${ filename }_png" url="${ filename }.png" />`;
  });

  //console.log(sceneNode);

  return sceneNode;
};
/*
const makeX3DFile = function makeX3DFile(data, filename) {
    let file = new Blob([data], {type: 'text/xml'});
    return file;
};

const makeZip = function makeZip(data, filename) {
};
*/

export default exportX3d;
