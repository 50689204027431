<template>
  <v-card>
    <v-dialog v-model="dialog" width="900">
      <template v-slot:activator="{ on, attrs }">
        <v-btn v-bind="attrs" v-on="on" class="order-btn" color="accent">
          Join the Launch List for $5 Off a Print!
        </v-btn>
      </template>
      <v-card>
        <v-card-title> Welcome to FabriCat Studio! </v-card-title>
        <v-card-subtitle>
          FabriCat Studio is PRE-LAUNCH. While the app loads, take some time to
          read about how it works!
        </v-card-subtitle>
        <v-card-text>
          <!-- Begin Mailchimp Signup Form -->
          <link
            href="//cdn-images.mailchimp.com/embedcode/classic-071822.css"
            rel="stylesheet"
            type="text/css"
          />
          <component :is="style" type="text/css" async>
            #mc_embed_signup { background: #fff; clear: left; font: 14px
            Helvetica, Arial, sans-serif; width: 600px; } /* Add your own
            Mailchimp form style overrides in your site stylesheet or in this
            style block. We recommend moving this block and the preceding CSS
            link to the HEAD of your HTML file. */
          </component>
          <div id="mc_embed_signup">
            <form
              action="https://studio.us6.list-manage.com/subscribe/post?u=ab314afce49a8f9670d972e8d&amp;id=34964f8e68&amp;f_id=00c627e3f0"
              method="post"
              id="mc-embedded-subscribe-form"
              name="mc-embedded-subscribe-form"
              class="validate"
              target="_blank"
              novalidate
            >
              <div id="mc_embed_signup_scroll">
                <h2>Sign Up for $5 Off Your First Print!</h2>
                <div class="indicates-required">
                  <span class="asterisk">*</span> indicates required
                </div>
                <div class="mc-field-group">
                  <label for="mce-EMAIL"
                    >Email Address <span class="asterisk">*</span>
                  </label>
                  <input
                    type="email"
                    value=""
                    name="EMAIL"
                    class="required email"
                    id="mce-EMAIL"
                    required
                  />
                  <span id="mce-EMAIL-HELPERTEXT" class="helper_text"></span>
                </div>
                <div class="mc-field-group">
                  <label for="mce-NAME"
                    >Full Name <span class="asterisk">*</span>
                  </label>
                  <input
                    type="text"
                    value=""
                    name="NAME"
                    class="required"
                    id="mce-NAME"
                    required
                  />
                  <span id="mce-NAME-HELPERTEXT" class="helper_text"></span>
                </div>
                <div class="mc-field-group">
                  <label for="mce-CATNAME">Cat's Name (Optional) </label>
                  <input
                    type="text"
                    value=""
                    name="CATNAME"
                    class=""
                    id="mce-CATNAME"
                  />
                  <span id="mce-CATNAME-HELPERTEXT" class="helper_text"></span>
                </div>
                <div hidden="true">
                  <input type="hidden" name="tags" value="1884153,1884157" />
                </div>
                <div id="mce-responses" class="clear">
                  <div
                    class="response"
                    id="mce-error-response"
                    style="display: none"
                  ></div>
                  <div
                    class="response"
                    id="mce-success-response"
                    style="display: none"
                  ></div>
                </div>
                <!-- real people should not fill this in and expect good things - do not remove this or risk form bot signups-->
                <div
                  style="position: absolute; left: -5000px"
                  aria-hidden="true"
                >
                  <input
                    type="text"
                    name="b_ab314afce49a8f9670d972e8d_34964f8e68"
                    tabindex="-1"
                    value=""
                  />
                </div>
                <div class="clear">
                  <input
                    type="submit"
                    value="Subscribe"
                    name="subscribe"
                    id="mc-embedded-subscribe"
                    class="button"
                  />
                </div>
              </div>
            </form>
          </div>
          <component
            :is="script"
            type="text/javascript"
            src="//s3.amazonaws.com/downloads.mailchimp.com/js/mc-validate.js"
            async
          ></component>
          <component :is="script" type="text/javascript" async>
            (function ($) { window.fnames = new Array(); window.ftypes = new
            Array(); fnames[0] = "EMAIL"; ftypes[0] = "email"; fnames[1] =
            "NAME"; ftypes[1] = "text"; fnames[2] = "CATNAME"; ftypes[2] =
            "text"; })(jQuery); var $mcj = jQuery.noConflict(true);
          </component>
          <!--End mc_embed_signup-->
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="closeDialog" color="primary">
            <span> Done! </span>
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
export default {
  data() {
    return {
      dialog: false,
    };
  },
  methods: {
    closeDialog() {
      this.dialog = false;
    },
  },
};
</script>
<style scoped>
.order-btn {
  z-index: 200;
  pointer-events: auto;
  display: block;
  position: absolute;
  bottom: 52px;
  right: 0;
}
</style>
