<template>
  <v-card
    @click="$emit('click')"
    :class="[
      'editor-button',
      'square-ratio',
      active ? 'active' : 'inactive',
      round ? 'rounded-circle' : '',
    ]"
    flat
  >
    <v-card
      flat
      :color="color ? color : 'rgb(255, 255, 255, 0)'"
      min-height="40px"
      :class="[
        round ? 'rounded-circle' : '',
        'd-flex',
        'justify-center',
        'align-center',
        'square-ratio',
      ]"
    >
      <v-img
        v-if="icon"
        :max-height="90"
        :max-width="90"
        :src="`images/icons/${icon}.png`"
        class="editor-icon square-ratio"
      />
    </v-card>
    <v-card flat class="label">
      {{ label }}
    </v-card>
  </v-card>
</template>

<script>
export default {
  props: {
    icon: String,
    label: {
      type: String,
      default: "",
    },
    active: {
      type: Boolean,
      default: false,
    },
    color: String,
    round: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },
};
</script>

<style>
.editor-button {
  font-size: 0.8em;
  font-weight: bold;
}

.editor-button.inactive {
  border: 4px solid;
  border-color: white;
}

.editor-button.active {
  border: 4px solid;
  border-color: var(--v-accent-lighten1) !important;
  background-color: var(--v-primary-lighten2) !important;
}

.label {
  text-align: center;
}

.editor-icon {
  max-width: 90px;
  max-height: 90px;
  width: 100%;
  height: auto;
}

.square-ratio {
  aspect-ratio: 1/1;
}
</style>
