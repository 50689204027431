<template>
  <v-card flat>
    <v-expansion-panels focusable mandatory flat accordion>
      <v-expansion-panel v-for="panel in panels" :key="panel.title">
        <v-expansion-panel-header color="var(--v-primary-lighten2)">
          <h3>
            {{ panel.title }}
          </h3>
        </v-expansion-panel-header>
        <v-expansion-panel-content color="var(--v-accent-lighten2)">
          <component :is="panel.component"></component>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </v-card>
</template>

<script>
import ComingSoon from "./ComingSoon.vue";
import HairLengthPanel from "./editorPanels/HairLengthPanel.vue";
export default {
  data() {
    return {
      panels: [
        { title: "hair length", component: HairLengthPanel },
        { title: "chonk", component: ComingSoon },
        { title: "pose", component: ComingSoon },
      ],
    };
  },
};
</script>
