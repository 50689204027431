<template>
  <v-select
    v-model="selectedCountry"
    :items="items"
    color="secondary"
    solo
    class="country-select"
    item-text="name"
    item-value="abbr"
    prepend-icon="mdi-truck"
    outlined
    dense
    hide-details
  ></v-select>
</template>

<script>
export default {
  data() {
    return {
      select: { name: "🇨🇦", abbr: "CA" },
      items: [
        { name: "🇨🇦", abbr: "CA" },
        { name: "🇺🇸", abbr: "US" },
      ],
    };
  },
  computed: {
    selectedCountry: {
      get: function () {
        return this.$store.state.country;
      },
      set: function (value) {
        console.log(value);
        this.$store.commit("setCountry", value);
      },
    },
  },
};
</script>

<style>
.country-select {
  z-index: 300;
  pointer-events: auto;
}
</style>
