<template>
  <v-container class="editor-container">
    <v-card class="selector-ui" flat>
      <v-tabs
        centered
        v-model="tab"
        active-class="active-tab"
        fixed-tabs
        background-color="primary"
        style="position: sticky; top: 0; z-index: 200"
      >
        <v-tab
          v-for="item in items"
          :key="item.name"
          @click="setViewpoint(item.view)"
        >
          {{ item.name }}
        </v-tab>
      </v-tabs>
    </v-card>
    <v-card flat class="tab-content-container">
      <v-tabs-items v-model="tab">
        <v-tab-item v-for="item in items" :key="item.name">
          <component :is="item.component" />
        </v-tab-item>
      </v-tabs-items>
    </v-card>
  </v-container>
</template>

<script>
import FurEditor from "./FurEditor.vue";
import FaceEditor from "./FaceEditor.vue";
import BodyEditor from "./BodyEditor.vue";
export default {
  data() {
    return {
      tab: null,
      items: [
        { name: "fur", view: "all", component: FurEditor },
        { name: "face", view: "face", component: FaceEditor },
        { name: "body", view: "all", component: BodyEditor },
      ],
    };
  },
  methods: {
    setViewpoint(viewLabel) {
      document
        .getElementById(`x3d-viewpoint-${viewLabel}`)
        .setAttribute("set_bind", "true");
    },
  },
};
</script>

<style scoped>
.selector-ui {
  z-index: 200;
  pointer-events: auto;
}

.active-tab {
  background-color: "primary";
}

.editor-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  max-height: 100%;
  overflow: hidden;
  padding: 0;
  margin: 0;
}

.tab-content-container {
  flex-grow: 1; /* Allow tab content to take up available space */
  overflow-y: auto; /* Allow vertical scrolling within tab content */
}
</style>
