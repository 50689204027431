<template>
  <v-dialog
    width="800"
    v-model="dialog"
    @click:outside="this.$store.commit('resetUploadStep')"
    :persistent="isUploading"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn v-bind="attrs" v-on="on" class="order-btn" color="accent">
        Order Print
      </v-btn>
    </template>
    <v-card class="modal">
      <v-card-title class="text-h5 lighten-2 text-center">
        Order a 3D Print
      </v-card-title>
      <v-stepper v-model="this.$store.state.uploadStep">
        <v-stepper-header>
          <v-spacer></v-spacer>
          <v-stepper-step :complete="this.$store.state.uploadStep > 1" step="1">
            Upload Design
          </v-stepper-step>
          <v-divider></v-divider>
          <v-stepper-step step="2"> Go to Checkout? </v-stepper-step>
          <v-spacer></v-spacer>
        </v-stepper-header>

        <v-stepper-items>
          <v-stepper-content step="1">
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="8">
                    <v-text-field
                      v-model="catName"
                      outlined
                      label="Your Cat's Name"
                      :rules="[rules.required]"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="4">
                    <v-btn
                      class="ma-2"
                      color="primary"
                      @click="$emit('upload')"
                    >
                      Add to Cart
                    </v-btn>
                  </v-col>
                </v-row>
                <v-divider></v-divider>
                <v-row>
                  <h3>What You Get</h3>
                </v-row>
                <v-row>
                  <div>
                    You are adding to your cart an 8cm full-colour 3D print
                    based on your design, fulfilled by our 3D-printing partner,
                    Shapeways! Please note that printing time and shipping time
                    can vary, and typically run between 1-2 weeks each.
                  </div>
                </v-row>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-dialog
                v-model="this.$store.state.isUploading"
                persistent
                width="300"
              >
                <v-card>
                  <v-card-text class="text-h6 text-center">
                    Uploading.<br />This may take a minute
                    <v-icon>mdi-cat</v-icon>
                    <br />
                    <v-progress-circular
                      indeterminate
                      color="primary"
                      :width="8"
                    ></v-progress-circular>
                  </v-card-text>
                </v-card>
              </v-dialog>
              <v-btn text @click="closeAndReset">Cancel</v-btn>
            </v-card-actions>
          </v-stepper-content>

          <v-stepper-content step="2">
            <v-card-text>
              <div>
                Cat has been uploaded and added to your cart!<br /><br />You can
                keep editing, and add more cats to your cart, or you can
                checkout now!
              </div>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="primary" text @click="checkout">Checkout</v-btn>
              <v-btn text @click="closeAndReset">Edit More</v-btn>
            </v-card-actions>
          </v-stepper-content>
        </v-stepper-items>
      </v-stepper>
    </v-card>
  </v-dialog>
</template>

<script>
const CHECKOUT_ENDPOINT = "https://orders.fabricat.studio/checkout";
export default {
  data() {
    return {
      dialog: false,
      isUploading: this.$store.state.isUploading,
      rules: {
        required: (value) => !!value || "required",
      },
    };
  },
  computed: {
    catName: {
      get() {
        return this.$store.state.catName;
      },
      set(catName) {
        this.$store.commit("setCatName", catName);
      },
    },
  },
  methods: {
    async checkout() {
      let formData = new FormData();
      formData.append("country", this.$store.state.country);
      const cartItems = this.$store.state.cart;
      cartItems.forEach((item) => {
        formData.append("cart[]", item.id);
      });
      let req = new XMLHttpRequest();
      req.open("POST", CHECKOUT_ENDPOINT);
      await req.send(formData);

      req.onreadystatechange = () => {
        if (req.readyState === XMLHttpRequest.DONE) {
          const res = JSON.parse(req.responseText);
          window.location.href = res.url;
        }
      };
    },
    closeAndReset() {
      this.dialog = false;
      this.$store.commit("resetUploadStep");
    },
  },
};
</script>

<style>
.modal {
  z-index: 300;
  pointer-events: auto;
}

.order-btn {
  z-index: 200;
  pointer-events: auto;
  display: block;
}
</style>
