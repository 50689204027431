var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{class:[
    'editor-button',
    'square-ratio',
    _vm.active ? 'active' : 'inactive',
    _vm.round ? 'rounded-circle' : '',
  ],attrs:{"flat":""},on:{"click":function($event){return _vm.$emit('click')}}},[_c('v-card',{class:[
      _vm.round ? 'rounded-circle' : '',
      'd-flex',
      'justify-center',
      'align-center',
      'square-ratio',
    ],attrs:{"flat":"","color":_vm.color ? _vm.color : 'rgb(255, 255, 255, 0)',"min-height":"40px"}},[(_vm.icon)?_c('v-img',{staticClass:"editor-icon square-ratio",attrs:{"max-height":90,"max-width":90,"src":`images/icons/${_vm.icon}.png`}}):_vm._e()],1),_c('v-card',{staticClass:"label",attrs:{"flat":""}},[_vm._v(" "+_vm._s(_vm.label)+" ")])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }