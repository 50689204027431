<template>
  <v-card>
    <v-dialog v-model="dialog" width="900" :persistent="sceneLoading">
      <v-card class="rounded-xl">
        <v-card-title class="d-flex justify-center align-center">
          <img width="200" src="images/logos/fabricat-logo-purple.png" />
        </v-card-title>
        <v-card-text>
          <AboutSlider></AboutSlider>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            :disabled="sceneLoading"
            @click="closeDialog"
            color="primary"
            size="x-large"
            class="px-12 py-10"
            style="font-size: 2rem"
          >
            <span v-if="sceneLoading">
              <v-progress-circular indeterminate></v-progress-circular>
              FabriCat Loading
            </span>
            <span v-else> Go! </span>
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import AboutSlider from "./AboutSlider.vue";

export default {
  data() {
    return {
      dialog: true,
    };
  },
  computed: {
    sceneLoading() {
      return this.$store.state.loading.scene;
    },
  },
  methods: {
    closeDialog() {
      this.dialog = false;
    },
  },
  components: { AboutSlider },
};
</script>
