<template>
  <v-container>
    <v-row dense no-gutters>
      <v-card width="100%" flat>
        <v-card-title> Eye Color </v-card-title>
        <v-item-group mandatory v-model="eyeColor">
          <v-row dense>
            <v-col v-for="color in eyeColors" :key="color">
              <v-item v-slot="{ active, toggle }" :value="color">
                <EditorButton
                  :active="active"
                  @click="toggle"
                  :label="color"
                ></EditorButton>
              </v-item>
            </v-col>
          </v-row>
        </v-item-group>
      </v-card>
    </v-row>
  </v-container>
</template>

<script>
import EditorButton from "../EditorButton.vue";
export default {
  data() {
    return {
      eyeColors: ["green", "yellow", "hazel", "blue", "orange"],
    };
  },
  computed: {
    eyeColor: {
      get: function () {
        return this.$store.state.catEditor.eyeColor;
      },
      set: function (color) {
        this.$store.commit("setEditorProperty", ["eyeColor", color]);
      },
    },
  },
  components: {
    EditorButton,
  },
};
</script>

<style>
.selected-item {
  border-width: 5px !important;
  border-color: var(--v-primary-base) !important;
}

.v-slider__tick--filled,
.v-slider__tick {
  background-color: var(--v-primary-base) !important;
}
</style>
