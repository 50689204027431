<template>
  <v-container>
    <v-item-group multiple v-model="selectedWhitespotting">
      <v-row>
        <v-spacer></v-spacer>
        <v-col
          v-for="whitespotting in whitespottings"
          :key="whitespotting.name"
          :cols="columnSize"
          class="pa-1"
        >
          <v-item v-slot="{ active, toggle }" :value="whitespotting.texture">
            <EditorButton
              :active="active"
              @click="toggle"
              :label="whitespotting.name"
              :icon="whitespotting.icon"
              round
            ></EditorButton>
          </v-item>
        </v-col>
        <v-spacer></v-spacer>
      </v-row>
    </v-item-group>
  </v-container>
</template>

<script>
import { WHITESPOTTINGS } from "../../helpers/whitespottingHelper.js";
import EditorButton from "../EditorButton.vue";
export default {
  data() {
    return {
      whitespottings: Object.keys(WHITESPOTTINGS).map((key) => ({
        label: key,
        ...WHITESPOTTINGS[key],
      })),
    };
  },
  computed: {
    selectedWhitespotting: {
      get: function () {
        return this.$store.state.catEditor.whitespotting;
      },
      set: function (whitespotting) {
        this.$store.commit("setEditorProperty", [
          "whitespotting",
          whitespotting,
        ]);
      },
    },
    columnSize() {
      return window.innerWidth > 1024 ? 3 : 4;
    },
  },
  components: {
    EditorButton,
  },
};
</script>

<style>
.selected-item {
  border-width: 5px !important;
  border-color: var(--v-primary-base) !important;
}

.v-slider__tick--filled,
.v-slider__tick {
  background-color: var(--v-primary-base) !important;
}
</style>
